import Swal, { SweetAlertOptions } from "sweetalert2";

export const BASE_URL: any = process.env.REACT_APP_BACKEND_URL;

export const API_URL = {
  LOGIN_USER: "user/loginUser",

  /* Product API */
  CREATE_PRODUCT: "product/addProduct",
  GET_PRODUCT_BY_ID: "product/getProductById/",
  UPDATE_PRODUCT: "product/updateProductById/",
  GET_ALL_PRODUCT: "product/getAllProduct",
  DELETE_PRODUCT_BY_ID: "product/deleteProductById/",
  GET_ALL_PRODUCT_NAME:"product/getAllProductName",

  /* Category API */
  CREATE_CATEGORY: "category/createCategory",
  UPDATE_CATEGORY_BY_ID: "category/updateCategoryById/",
  GET_ALL_CATEGORY: "category/getAllCategory",
  FETCH_ALL_CATEGORIES: "category/fetchAllCategories",
  GET_CATEGORY_BY_ID: "category/getCategoryById/",
  DELETE_CATEGORY_BY_ID: "category/deleteCategoryById/",

  /* Sub Category API */
  CREATE_SUB_CATEGORY: "subCategory/createSubCategory",
  GET_ALL_SUB_CATEGORY: "subCategory/getAllSubCategory",
  GET_SUB_CATEGORY_BY_ID: "subCategory/getSubCategoryById/",
  UPDATE_SUB_CATEGORY_BY_ID: "subCategory/updateSubCategoryById/",
  DELETE_SUB_CATEGORY_BY_ID: "subCategory/deleteSubCategoryById/",
  GET_ALL_CATEGORY_NAME: "category/getAllCategoryName",
  GET_ALL_SUB_CATEGORY_NAME: "subCategory/getAllSubCategoryName",

  /* Ingrediant API */
  CREATE_INGREDIENT: "ingredient/createIngredient",
  GET_ALL_INGREDIENT: "ingredient/getAllIngredient",
  GET_INGREDIENT_BY_ID: "ingredient/getIngredientById/",
  UPDATE_INGREDIENT_BY_ID: "ingredient/updateIngredientById/",
  DELETE_INGREDIENT_BY_ID: "ingredient/deleteIngredientById/",
  GET_ALL_INGREDIENT_NAME: "ingredient/getAllIngredientName",

  /* Sku Table API */
  CREATE_SKU: "sku/createSku",
  GET_SKU_BY_ID: "sku/getSkuById/",
  GET_ALL_SKU: "sku/getAllSku",
  UPDATE_SKU_BY_ID: "sku/updateSkuById/",
  DELETE_SKU_BY_ID: "sku/deleteSkuById/",
  GET_ALL_SKU_VARIANT: "sku/getAllSkuVariant",

  /* Marketing Table API */
  CREATE_MARKETING: "market/createMarket",
  GET_MARKETING_BY_ID: "market/getMarketById/",
  GET_ALL_MARKETING: "market/getAllMarket",
  UPDATE_MARKETING_BY_ID: "market/updateMarketById/",
  DELETE_MARKETING_BY_ID: "market/deleteMarketById/",
  GET_ALL_MARKETING_NAME: "market/getAllMarketingName",

  /* Dosage Assistance Table API */
  CREATE_DOSAGE_ASSISTANCE: "dosageAssistance/createDosageAssistance",
  GET_DOSAGE_ASSISTANCE_BY_ID: "dosageAssistance/getDosageAssistanceById/",
  GET_ALL_DOSAGE_ASSISTANCE: "dosageAssistance/getAllDosageAssistance",
  UPDATE_DOSAGE_ASSISTANCE_BY_ID:
    "dosageAssistance/updateDosageAssistanceById/",
  DELETE_DOSAGE_ASSISTANCE_BY_ID:
    "dosageAssistance/deleteDosageAssistanceById/",
  GET_ALL_DOSAGE_ASSISTANCE_NAME: "dosageAssistance/getAllDosageAssistanceName",

  /* Benefit Table API */
  CREATE_BENEFIT: "benefit/createBenefit",
  GET_ALL_BENEFIT: "benefit/getAllBenefit",
  UPDATE_BENEFIT_BY_ID: "benefit/updateBenefitById/",
  DELETE_BENEFIT_BY_ID: "benefit/deleteBenefitById/",
  GET_BENEFIT_BY_ID: "benefit/getBenefitById/",
  GET_ALL_BENEFIT_NAME: "benefit/getAllBenefitName",

  /* Faq Table API */
  CREATE_FAQ: "faq/createFaq",
  GET_ALL_FAQ: "faq/getAllFaq",
  UPDATE_FAQ_BY_ID: "faq/updateFaqById/",
  DELETE_FAQ_BY_ID: "faq/deleteFaqById/",
  GET_FAQ_BY_ID: "faq/getFaqById/",
  GET_ALL_FAQ_NAME: "faq/getAllFaqName",

  /*Classes Table API */
  ADD_CLASS: "class/addClass",
  GET_ALL_CLASS: "class/getAllClass",
  UPDATE_CLASS_BY_ID: "class/updateClassById/",
  DELETE_CLASS_BY_ID: "class/deleteClassById/",
  GET_CLASS_BY_ID: "class/getClassById/",

  /*  Chapter Table Table API */
  ADD_CHAPTER: "chapter/addChapter",
  GET_ALL_CHAPTER: "chapter/getAllChapter",
  UPDATE_CHAPTER_BY_ID: "chapter/updateChapterById/",
  DELETE_CHAPTER_BY_ID: "chapter/deleteChapterById/",
  GET_CHAPTER_BY_ID: "chapter/getChapterById/",
  GET_CLASS_BY_CHAPTER_ID: "chapter/getClassByChapterId",

  /* Course Table Table API */
  ADD_COURSE: "course/addCourse",
  GET_ALL_COURSE: "course/getAllCourse",
  UPDATE_COURSE_BY_ID: "course/updateCourseById/",
  DELETE_COURSE_BY_ID: "course/deleteCourseById/",
  GET_COURSE_BY_ID: "course/getCourseById/",
  GET_CHAPTER_BY_COURSE_ID: "course/getChapterByCourseId",

  /* Keyword Table API */
  CREATE_KEYWORD: "keyword/createKeyword",
  GET_ALL_KEYWORD: "keyword/getAllKeyword",
  UPDATE_KEYWORD_BY_ID: "keyword/updateKeywordById/",
  DELETE_KEYWORD_BY_ID: "keyword/deleteKeywordById/",
  GET_KEYWORD_BY_ID: "keyword/getKeywordById/",
  GET_ALL_KEYWORD_NAME: "keyword/getAllKeywordName",

  /* How It Works Table API */
  CREATE_HOW_IT_WORKS: "howItWork/createHowItWork",
  GET_ALL_HOW_IT_WORKS: "howItWork/getAllHowItWork",
  UPDATE_HOW_IT_WORKS_BY_ID: "howItWork/updateHowItWorkById/",
  DELETE_HOW_IT_WORKS_BY_ID: "howItWork/deleteHowItWorkById/",
  GET_HOW_IT_WORKS_BY_ID: "howItWork/getHowItWorkById/",
  GET_ALL_HOW_IT_WORK_LIST: "howItWork/getAllHowItWorkList",

  /* User Table API */
  CREATE_USER: "user/registerUser",
  GET_ALL_USER: "user/getAllUserByLimit",
  UPDATE_USER_BY_ID: "user/updateUserById/",
  DELETE_USER_BY_ID: "user/deleteUserById/",
  GET_USER_BY_ID: "user/getUserById/",
  REGISTER_USER: "user/createAdminUser",
  VERIFY_ADMIN_USER: "/user/verifyAdminUser",
  CREATE_ADMIN_USER_PASSWORD: "/user/createAdminUserPassword",
  GET_ALL_METRIC_BY_USER: "/user/getAllMetricByUser",
  GET_ALL_MEDICAL_REPORT_BY_USER: "/user/getMedicalReportByUserId",
  GET_CLINICAL_REPORT_BY_USER_ID: "/medicalDetails/generateMetricByPdfId",
  GET_PDF_DETAIL_BY_USER_ID:"/medicalDetails/getMetricDetailByPdfId",

  /* Delivery Options Table API */
  CREATE_DELIVERY_OPTION: "deliveryOption/addNewDeliveryOption",
  GET_ALL_DELIVERY_OPTION: "deliveryOption/getAllDeliveryOptionByLimit",
  UPDATE_DELIVERY_OPTIONS_BY_ID: "deliveryOption/updateDeliveryOptionById/",
  DELETE_DELIVERY_OPTIONS_BY_ID: "deliveryOption/deleteDeliveryOptionById/",
  GET_DELIVERY_OPTIONS_BY_ID: "deliveryOption/getDeliveryOptionById/",

  /* Trainer Table API */
  CREATE_TRAINER: "trainer/createTrainer",
  GET_ALL_TRAINERS: "trainer/getAllTrainer",
  UPDATE_TRAINER_BY_ID: "trainer/updateTrainerById/",
  DELETE_TRAINER_BY_ID: "trainer/deleteTrainerById/",
  GET_TRAINER_BY_ID: "trainer/getTrainerById/",

  /* Course Faq Table API */
  CREATE_COURSE_FAQ: "courseFaq/addCourseFaq",
  GET_ALL_COURSE_FAQ: "courseFaq/getAllCourseFaq",
UPDATE_COURSE_FAQ_ID: "courseFaq/updateCourseFaqById/",
  DELETE_COURSE_FAQ_BY_ID: "courseFaq/deleteCourseFaqById/",
  GET_COURSE_FAQ_BY_ID: "courseFaq/getCourseFaqById/",

  /* Master Table API */
  CREATE_MASTER: "master/createMaster",
  GET_ALL_MASTER: "master/getAllMaster",
  UPDATE_MASTER_BY_ID: "master/updateMasterById/",
  DELETE_MASTER_BY_ID: "master/deleteMasterById/",
  GET_MASTER_BY_ID: "master/getMasterById/",

  /* ChatGpt Table API */
  GET_OPENAI_RESPONSE_BY_QUERY: "openai/getOpenAIResponseByQuery",
  GET_CONVERSATIONS_HISTORY_BY_USER_EMAIL:
    "conversation/getConversationsHistoryByUserEmail",

  /* Order Table API */
  GET_ALL_ORDER: "order/getAllOrder",
  GET_ORDER_BY_ID: "order/getOrderById/",

  /* Promo Code Table API*/
  GET_ALL_PROMO_CODES: "promoCode/getAllPromoCodesByLimit",
  CREATE_PROMO_CODES: "promoCode/createPromoCode",
  UPDATE_PROMO_CODE_DETAILS: "promoCode/updatePromoCodeDetails/",
  GET_PROMO_CODE_DETAILS_BY_ID: "promoCode/getPromoCodeDetailsById/",
  DELETE_PROMO_CODE_BY_ID: "promoCode/deletePromoCodeById/",

  /* Bot Table API*/
  GET_ALL_BOTS: "bot/getAllBots",
  CREATE_BOTS: "bot/createBot",
  UPDATE_BOT_BY_ID: "bot/updateBotById/",
  GET_BOT_BY_ID: "bot/getBotById/",
  DELETE_BOT_BY_ID: "bot/deleteBotById/",

  /* Role And Permission */
  GET_ALL_ROLE_AND_PERMISSION: "roleAndPermission/getAllRoleAndPermission",
  UPDATE_ROLE_AND_PERMISSION: "roleAndPermission/updateRoleAndPermission",

  /* User Profile Category Table API*/
  ADD_USER_PROFILE_CATEGORY: "userProfileCategory/addUserProfileCategory",
  UPDATE_USER_PROFILE_CATEGORY_BY_ID:
    "userProfileCategory/updateUserProfileCategoryById/",
  GET_ALL_USER_PROFILE_CATEGORY:
    "userProfileCategory/getAllUserProfileCategory",
  GET_USER_PROFILE_CATEGORY_BY_ID:
    "userProfileCategory/getUserProfileCategoryById/",
  DELETE_USER_PROFILE_CATEGORY_BY_ID:
    "userProfileCategory/deleteUserProfileCategoryById/",

  /* User Profile Sub Category Table API*/
  ADD_USER_PROFILE_SUB_CATEGORY:
    "userProfileSubCategory/addUserProfileSubCategory",
  UPDATE_USER_PROFILE_SUB_CATEGORY_BY_ID:
    "userProfileSubCategory/updateUserProfileSubCategoryById/",
  GET_ALL_USER_PROFILE_SUB_CATEGORY:
    "userProfileSubCategory/getAllUserProfileSubCategory",
  GET_USER_PROFILE_SUB_CATEGORY_BY_ID:
    "userProfileSubCategory/getUserProfileSubCategoryById/",
  DELETE_USER_PROFILE_SUB_CATEGORY_BY_ID:
    "userProfileSubCategory/deleteUserProfileSubCategoryById/",

  /* Dashboard */
  GET_TOTAL_COUNTER: "/dashboard/getTotalCounter",
  GET_NOTIFICATION: "/dashboard/getNotification",
  READ_NOTIFICATION: "/dashboard/readNotification/",

  /* User Question Table API*/
  ADD_PROFILE_QUESTION: "profileQuestion/addProfileQuestion",
  GET_PROFILE_QUESTION_BY_ID: "profileQuestion/getProfileQuestionById/",
  GET_ALL_PROFILE_QUESTION: "profileQuestion/getAllProfileQuestion",
  UPDATE_PROFILE_QUESTION_BY_ID: "profileQuestion/updateProfileQuestionById/",
  DELETE_PROFILE_QUESTION_BY_ID: "profileQuestion/deleteProfileQuestionById/",

  /* Hardware Specific Table API*/
  GET_ALL_HARDWARE_SPECIFIC_BY_USER_ID:
    "/hardwareSpecificDetail/getAllHardwareSpecificDetailsByUserId",
  GET_HARDWARE_SPECIFIC_BY_ID:
    "/hardwareSpecificDetail/getHardwareSpecificDetailById/",
  GET_ALL_DEVICES_BY_USER_ID:"/userDevice/getAllDeviceByUserIds/",

  /* Default Conversation Table API*/
  ADD_DEFAULT_CONVERSATION:
    "/defaultUserConversation/addDefaultUserConversation",
  EDIT_DEFAULT_CONVERSATION:
    "/defaultUserConversation/updateDefaultUserConversationById/",
  GET_DEFAULT_CONVERSATION:
    "/defaultUserConversation/getDefaultUserConversation",

  /* Metric  */
  CREATE_METRIC: "/metric/createMetric",
  GET_ALL_METRIC: "/metric/getAllMetric",
  UPDATE_METRIC_BY_ID: "/metric/updateMetricById/",
  DELETE_METRIC_BY_ID: "/metric/deleteMetricById/",
  GET_METRIC_BY_ID: "/metric/getMetricById/",
  GET_ALL_METRIC_NAME: "/metric/getAllMetricName",

  // level one metric
  CREATE_LEVEL_ONE_METRIC_INFO: "/levelOneMetricInfo/createLevelOneMetricInfo",
  GET_ALL_LEVEL_ONE_METRIC_INFO: "/levelOneMetricInfo/getAllLevelOneMetricInfo",
  UPDATE_LEVEL_ONE_METRIC_INFO_BY_ID:
    "/levelOneMetricInfo/updateLevelOneMetricInfoById/",
  DELETE_LEVEL_ONE_METRIC_INFO_BY_ID:
    "/levelOneMetricInfo/deleteLevelOneMetricInfoById/",
  GET_LEVEL_ONE_METRIC_INFO_BY_ID:
    "/levelOneMetricInfo/getLevelOneMetricInfoById/",
    GET_ALL_LEVEL_ONE_NAME: "/levelOneMapping/getAllLevelOneMappingName",
  
    // level zero metric
    CREATE_LEVEL_ZERO_METRIC_INFO: "/levelZeroMetricInfo/createLevelZeroMetricInfo",
    GET_ALL_LEVEL_ZERO_METRIC_INFO: "/levelZeroMetricInfo/getAllLevelZeroMetricInfo",
    UPDATE_LEVEL_ZERO_METRIC_INFO_BY_ID:
      "/levelZeroMetricInfo/updateLevelZeroMetricInfoById/",
    DELETE_LEVEL_ZERO_METRIC_INFO_BY_ID:
      "/levelZeroMetricInfo/deleteLevelZeroMetricInfoById/",
    GET_LEVEL_ZERO_METRIC_INFO_BY_ID:
      "/levelZeroMetricInfo/getLevelZeroMetricInfoById/",

  /* Level One Mapping Table API*/
  CREATE_LEVEL_ONE_MAPPING: "/levelOneMapping/createLevelOneMapping",
  GET_ALL_LEVEL_ONE_MAPPING: "/levelOneMapping/getAllLevelOneMapping",
  UPDATE_LEVEL_ONE_MAPPING_BY_ID: "/levelOneMapping/updateLevelOneMappingById/",
  DELETE_LEVEL_ONE_MAPPING_BY_ID: "/levelOneMapping/deleteLevelOneMappingById/",
  GET_LEVEL_ONE_MAPPING_BY_ID: "/levelOneMapping/getLevelOneMappingById/",

  /* Level One Mapping Table API*/
  CREATE_LEVEL_ZERO_MAPPING: "/levelZeroMapping/createLevelZeroMapping",
  GET_ALL_LEVEL_ZERO_MAPPING: "/levelZeroMapping/getAllLevelZeroMapping",
  UPDATE_LEVEL_ZERO_MAPPING_BY_ID: "/levelZeroMapping/updateLevelZeroMappingById/",
  DELETE_LEVEL_ZERO_MAPPING_BY_ID: "/levelZeroMapping/deleteLevelZeroMappingById/",
  GET_LEVEL_ZERO_MAPPING_BY_ID: "/levelZeroMapping/getLevelZeroMappingById/",
  /* Cronjob Table API*/
  LEVEL_ONE_METRIC_INFO: "/runMetric/runLevelOneMetricInfo",
  LEVEL_TWO_METRIC_INFO: "/runMetric/runLevelTwoMetricInfo",

  /* Mobile Intro Screen Table API*/
  ADD_MOBILE_SCREEN: "/introScreenDetail/registerIntroScreenDetail",
  GET_MOBILE_SCREEN_BY_ID: "/introScreenDetail/getIntroScreenDetailById/",
  GET_ALL_MOBILE_SCREEN_DETAIL: "/introScreenDetail/getAllIntroScreenDetail",
  UPDATE_MOBILE_SCREEN_DETAIL_BY_ID:
    "/introScreenDetail/updateIntroScreenDetailById/",
  DELETE_MOBILE_SCREEN_BY_ID: "/introScreenDetail/deleteIntroScreenDetailById/",
  INTRO_SCREEN_DRAG_ORDER: "/introScreenDetail/dragOrderByOrder",

  /* User Detail Screen Table API*/
  ADD_USER_DETAIL_SCREEN: "/userDetailScreen/createUserDetailScreen",
  GET_USER_DETAIL_SCREEN_BY_ID: "/userDetailScreen/getUserDetailScreenById/",
  GET_ALL_USER_DETAIL_SCREEN: "/userDetailScreen/getAllUserDetailScreen",
  UPDATE_USER_DETAIL_SCREEN_BY_ID:
    "/userDetailScreen/updateUserDetailScreenById/",
  DELETE_USER_DETAIL_SCREEN_BY_ID:
    "/userDetailScreen/deleteUserDetailScreenById/",
  USER_DETAIL_SCREEN_DRAG_ORDER: "/userDetailScreen/dragUserOrderByOrder",
  GET_ALL_INTEREST_QUESTION: "/interestsQuestion/getAllInterestQuestion",

  /* Interest Table API*/
  ADD_INTEREST: "/interests/createInterest",
  GET_INTEREST_BY_ID: "/interests/getInterestById/",
  GET_ALL_INTEREST: "/interests/getAllInterest",
  UPDATE_INTEREST_BY_ID: "/interests/updateInterestById/",
  DELETE_INTEREST_BY_ID: "/interests/deleteInterestById/",
  INTEREST_DRAG_ORDER: "/interests/dragInterestCountByInterestCount",

  /* Interest Question Table API*/
  CREATE_INTEREST_QUESTION: "/interestsQuestion/createInterestQuestion",
  GET_ALL_INTEREST_QUESTION_NAME: "/interestsQuestion/getAllInterestQuestion",
  UPDATE_INTEREST_QUESTION_ID: "/interestsQuestion/updateInterestQuestionById/",
  DELETE_INTEREST_QUESTION_BY_ID:
    "/interestsQuestion/deleteInterestQuestionById/",
  GET_INTEREST_QUESTION_BY_ID: "/interestsQuestion/getInterestQuestionById/",
  GET_INTEREST_QUESTION_ANSWER_BY_ID:
    "/interestsQuestionAnswer/getInterestQuestionAnswerByUserId",

  //Goal
  CREATE_GOAL: "goal/createGoal",
  GET_ALL_GOAL: "goal/getAllGoal",
  UPDATE_GOAL_BY_ID: "goal/updateGoalById/",
  DELETE_GOAL_BY_ID: "goal/deleteGoalById/",
  GET_GOAL_BY_ID: "goal/getGoalById/",
  GET_ALL_GOAL_NAME: "goal/getAllGoalName",

  //Lab Test
  CREATE_LAB_TEST: "labTest/createLabTest",
  UPDATE_LAB_TEST_BY_ID: "labTest/updateLabTest/",
  GET_ALL_LAB_TEST: "labTest/getAllLabTest",
  GET_LAB_TEST_BY_ID: "labTest/getLabTestById/",
  DELETE_LAB_TEST_BY_ID: "labTest/deleteLabTestById/",

  /* Device Table API */
  CREATE_DEVICE: "device/addDevice",
  GET_ALL_DEVICE:"device/getAllDevice",
  UPDATE_DEVICE_BY_ID: "device/updateDeviceById/",
  GET_DEVICE_BY_ID: "device/getDeviceById/",
  GET_ALL_LIST_OF_DEVICE:"device/getAllListOfDevice",

   // Assessment
   CREATE_ASSESSMENT: "assessment/createAssessment",
   GET_ALL_ASSESSMENT: "assessment/getAllAssessment",
   UPDATE_ASSESSMENT_BY_ID: "assessment/updateAssessmentById/",
   DELETE_ASSESSMENT_BY_ID: "assessment/deleteAssessmentById/",
   GET_ASSESSMENT_BY_ID: "assessment/getAssessmentById/",

     //Assessment Question
   CREATE_ASSESSMENT_QUESTION: "assessmentQuestion/createAssessmentQuestion",
   GET_ALL_ASSESSMENT_QUESTION: "assessmentQuestion/getAllAssessmentQuestion",
   UPDATE_ASSESSMENT_QUESTION_BY_ID: "assessmentQuestion/updateAssessmentQuestionById/",
   DELETE_ASSESSMENT_QUESTION_BY_ID: "assessmentQuestion/deleteAssessmentQuestionById/",
   GET_ASSESSMENT_QUESTION_BY_ID: "assessmentQuestion/getAssessmentQuestionById/",
   GET_ALL_ASSESSMENT_QUESTION_NAME: "assessmentQuestion/getAllAssessmentQuestionName",
   GET_ASSESSMENT_QUESTION_ANSWER_BY_ID:
    "/assessmentsQuestionAnswer/getAssessmentQuestionAnswerByUserId",

    //Recommendation Rule
   CREATE_RECOMMENDATION_RULE: "recommendationRule/addRecommendationRule",
   GET_ALL_RECOMMENDATION_RULE: "recommendationRule/getAllRecommendationRule",
   UPDATE_RECOMMENDATION_RULE_BY_ID: "recommendationRule/updateRecommendationRuleById/",
   DELETE_RECOMMENDATION_RULE_BY_ID: "recommendationRule/deleteRecommendationRuleById/",
   GET_RECOMMENDATION_RULE_BY_ID: "recommendationRule/getRecommendationRuleById/",
   GET_ALL_RECOMMENDATION_RULE_NAME: "recommendationRule/getAllRecommendationRuleName",

   //Recipe
   CREATE_RECIPE: "recipe/createRecipe",
   GET_ALL_RECIPE: "recipe/getAllRecipe",
   UPDATE_RECIPE_BY_ID: "recipe/updateRecipeById/",
   DELETE_RECIPE_BY_ID: "recipe/deleteRecipeById/",
   GET_RECIPE_BY_ID: "recipe/getRecipeById/",
   GET_ALL_RECIPE_NAME: "recipe/getAllRecipeName",

   //Recipe Bundle
   CREATE_RECIPE_BUNDLE: "recipeBundle/createRecipeBundle",
   GET_ALL_RECIPE_BUNDLE: "recipeBundle/getAllRecipeBundle",
   UPDATE_RECIPE_BUNDLE_BY_ID: "recipeBundle/updateRecipeBundleById/",
   DELETE_RECIPE_BUNDLE_BY_ID: "recipeBundle/deleteRecipeBundleById/",
   GET_RECIPE_BUNDLE_BY_ID: "recipeBundle/getRecipeBundleById/",
   GET_ALL_RECIPE_BUNDLE_NAME: "recipeBundle/getAllRecipeBundleName",

   /* Nugget Tag API */
  CREATE_NUGGET_TAG: "nuggetTag/addNuggetTag",
  GET_ALL_NUGGET_TAG: "nuggetTag/getAllNuggetTag",
  GET_NUGGET_TAG_BY_ID: "nuggetTag/getNuggetTagById/",
  UPDATE_NUGGET_TAG_BY_ID: "nuggetTag/updateNuggetTagById/",
  DELETE_NUGGET_TAG_BY_ID: "nuggetTag/deleteNuggetTagById/",
  GET_ALL_NUGGET_TAG_NAME: "nuggetTag/getAllNuggetTagName",
  GET_ALL_NUGGET_TAG_BY_TYPE: "nuggetTag/getAllNuggetTagByType",
  GET_ALL_SECONDARY_NUGGET_TAG_BY_PRIMARY_NUGGET_TAG: "nuggetTag/getAllSecondaryNuggetTagByPrimaryNuggetTag/",
  UPLOAD_NUGGET_TAG_SHEET: "nuggetTag/uploadNuggetTagSheet",

  /* Nugget API */
  CREATE_NUGGET: "nugget/addNugget",
  GET_ALL_NUGGET: "nugget/getAllNugget",
  GET_NUGGET_BY_ID: "nugget/getNuggetById/",
  UPDATE_NUGGET_BY_ID: "nugget/updateNuggetById/",
  DELETE_NUGGET_BY_ID: "nugget/deleteNuggetById/",
  GET_ALL_NUGGET_NAME: "nugget/getAllNuggetName",

  /* Privacy Policy API */
  CREATE_PRIVACY_POLICY: "privacyPolicy/createPrivacyPolicy",
  GET_ALL_PRIVACY_POLICY: "privacyPolicy/getAllPrivacyPolicy",
  GET_PRIVACY_POLICY_BY_ID: "privacyPolicy/getPrivacyPolicyById/",
  UPDATE_PRIVACY_POLICY_BY_ID: "privacyPolicy/updatePrivacyPolicyById/",
  DELETE_PRIVACY_POLICY_BY_ID: "privacyPolicy/deletePrivacyPolicyById/",
  GET_ALL_PRIVACY_POLICY_NAME: "privacyPolicy/getAllPrivacyPolicyName",

  /* Terms Of Use Policy API */
  CREATE_TERMS_OF_USE_POLICY: "termsOfUsePolicy/createTermsOfUsePolicy",
  GET_ALL_TERMS_OF_USE_POLICY: "termsOfUsePolicy/getAllTermsOfUsePolicy",
  GET_TERMS_OF_USE_POLICY_BY_ID: "termsOfUsePolicy/getTermsOfUsePolicyById/",
  UPDATE_TERMS_OF_USE_POLICY_BY_ID: "termsOfUsePolicy/updateTermsOfUsePolicyById/",
  DELETE_TERMS_OF_USE_POLICY_BY_ID: "termsOfUsePolicy/deleteTermsOfUsePolicyById/",
  GET_ALL_TERMS_OF_USE_POLICY_NAME: "termsOfUsePolicy/getAllTermsOfUsePolicyName",

  /* Device Category API */
  CREATE_DEVICE_CATEGORY: "deviceCategory/createDeviceCategory",
  GET_ALL_DEVICE_CATEGORY: "deviceCategory/getAllDeviceCategory",
  GET_DEVICE_CATEGORY_BY_ID: "deviceCategory/getDeviceCategoryById/",
  UPDATE_DEVICE_CATEGORY_BY_ID: "deviceCategory/updateDeviceCategoryById/",
  DELETE_DEVICE_CATEGORY_BY_ID: "deviceCategory/deleteDeviceCategoryById/",
  GET_ALL_DEVICE_CATEGORY_NAME: "deviceCategory/getAllDeviceCategoryName",

  /* Device Sub Category API */
  CREATE_DEVICE_SUB_CATEGORY: "deviceSubCategory/createDeviceSubCategory",
  GET_ALL_DEVICE_SUB_CATEGORY: "deviceSubCategory/getAllDeviceSubCategory",
  GET_DEVICE_SUB_CATEGORY_BY_ID: "deviceSubCategory/getDeviceSubCategoryById/",
  UPDATE_DEVICE_SUB_CATEGORY_BY_ID: "deviceSubCategory/updateDeviceSubCategoryById/",
  DELETE_DEVICE_SUB_CATEGORY_BY_ID: "deviceSubCategory/deleteDeviceSubCategoryById/",
  GET_ALL_DEVICE_SUB_CATEGORY_NAME: "deviceSubCategory/getAllDeviceSubCategoryName",

  /* Device Model API */
  CREATE_DEVICE_MODEL: "deviceModel/addDeviceModel",
  GET_ALL_DEVICE_MODEL: "deviceModel/getAllDeviceModel",
  GET_DEVICE_MODEL_BY_ID: "deviceModel/getDeviceModelById/",
  UPDATE_DEVICE_MODEL_BY_ID: "deviceModel/updateDeviceModelById/",
  DELETE_DEVICE_MODEL_BY_ID: "deviceModel/deleteDeviceModelById/",
  GET_ALL_DEVICE_MODEL_NAME: "deviceModel/getAllDeviceModelName",
};

export const ROUTING_CONSTANT = {
  PRODUCT: "/product",
  PRODUCT_VIEW: "/product-view",
  USERS_VIEW:"/users-view",
  CATEGORY: "/device-category",
  CATEGORY_VIEW: "/device-category-view",
  SUB_CATEGORY: "/device-subcategory",
  SUB_CATEGORY_VIEW: "/device-subcategory-view",
  MODEL: "/device-model",
  MODEL_VIEW: "/device-model-view",
  SKU: "/sku",
  SKU_VIEW: "/sku-view",
  INGREDIENT: "/ingredient",
  INGREDIENT_VIEW: "/ingredient-view",
  MARKETING: "/marketing",
  MARKETING_VIEW: "/marketing-view",
  DOSAGE_ASSISTANCE: "/dosageAssistance",
  DOSAGE_ASSISTANCE_VIEW: "/dosageAssistance-view",
  BENEFIT: "/benefit",
  BENEFIT_VIEW: "/benefit-view",
  FAQ: "/faq",
  FAQ_VIEW: "/faq-view",
  KEYWORD: "/keyword",
  KEYWORD_VIEW: "/keyword-view",
  HOW_IT_WORK: "/how-it-work",
  HOW_IT_WORK_VIEW: "/how-it-work-view",
  USER: "/user",
  USER_HARDWARE_SPECIFIC: "/user-hardware-specific",
  USER_METRIC_SPECIFIC: "/user-metric-specific",
  USER_MEDICAL_SPECIFIC: "/user-medical-specific",
  USER_VIEW: "/user-view",
  DELIVERY_OPTIONS: "/delivery-options",
  DELIVERY_OPTIONS_VIEW: "/delivery-options-view",
  ORDERS: "/orders",
  ORDERS_VIEW: "/orders-view",
  PROMO_CODE: "/promocode",
  PROMO_CODE_VIEW: "/promocode-view",
  ROLES_PERMISSION: "/roles-permission-view",
  TRAINER: "/trainer",
  TRAINER_VIEW: "/trainer-view",
  CLASS: "/class",
  CLASS_VIEW: "/class-view",
  CHAPTER: "/chapter",
  CHAPTER_VIEW: "/chapter-view",
  COURSE: "/course",
  COURSE_VIEW: "/course-view",
  MASTER: "/master",
  MASTER_VIEW: "/master-view",
  COURSE_FAQ: "/coursefaq",
  COURSE_FAQ_VIEW: "/coursefaq-view",
  CHATGPT_VIEW: "/chatgpt-view",
  SELF_TRAIN_VIEW: "/self-train-view",
  SHOP_VIEW: "/shop-view",
  BOT: "/bot",
  BOT_VIEW: "/bot-view",
  ADMIN_VIEW: "/admin-view",
  STORE_VIEW: "/store-view",
  POLICY_VIEW: "/policy-view",
  NUGGETS_MENU_VIEW:"/nuggets-view",
  DATA_VIEW: "/data-view",
  SETTING_VIEW: "/setting-view",
  INTEREST_VIEW: "/interest-view",
  USER_PROFILE_CATEGORY: "/user-profile-category",
  SUB_METRIC: "/sub-metric",
  USER_PROFILE_CATEGORY_VIEW: "/user-profile-category-view",
  SUB_METRIC_VIEW: "/sub-metric-view",
  USER_PROFILE_SUB_CATEGORY: "/user-profile-sub-category",
  USER_PROFILE_SUB_CATEGORY_VIEW: "/user-profile-sub-category-view",
  USER_PROFILE_QUESTION: "/user-profile-question",
  USER_PROFILE_QUESTION_VIEW: "/user-profile-question-view",
  DEFAULT_CONVERSATION: "/default-conversation",
  CRON_JOB: "/cron-job",
  CRON_JOB_VIEW: "/cron-job-view",
  MOBILE_SCREEN: "/mobile-screen",
  MOBILE_SCREEN_VIEW: "/mobile-screen-view",
  INTERESTS: "/interests",
  INTERESTS_VIEW: "/interests-view",
  INTERESTS_QUESTIONS: "/interests-questions",
  INTERESTS_QUESTIONS_VIEW: "/interests-questions-view",
  USER_DETAIL_SCREEN: "/user-detail-screen",
  USER_DETAIL_SCREEN_VIEW: "/user-detail-screen-view",
  LEVEL_ONE_MAPPING: "/level-one-mapping",
  LEVEL_ONE_MAPPING_VIEW: "/level-one-mapping-view",
  LEVEL_ZERO_MAPPING: "/level-zero-mapping",
  LEVEL_ZERO_MAPPING_VIEW: "/level-zero-mapping-view",
  GOAL: "/goal",
  GOAL_VIEW: "/goal-view",
  LAB_TEST: "/lab-test",
  LAB_TEST_VIEW: "/lab-test-view",
  DEVICE: "/device",
  DEVICE_VIEW: "/device-view",
  ASSESSMENT: "/assessment",
  ASSESSMENT_VIEW: "/assessment-view",
  ASSESSMENT_QUESTION: "/assessment-question",
  ASSESSMENT_QUESTION_VIEW: "/assessment-question-view",
  RECOMMENDATION_RULE: "/recommendation-rule",
  RECOMMENDATION_RULE_VIEW: "/recommendation-rule-view",
  RECIPE: "/recipe",
  RECIPE_VIEW: "/recipe-view",
  RECIPE_BUNDLE: "/recipe-bundle",
  RECIPE_BUNDLE_VIEW: "/recipe-bundle-view",
  NUGGETS: "/nuggets",
  NUGGETS_VIEW: "/nuggets-view",
  NUGGETS_TAG: "/nuggets-tag",
  NUGGETS_TAG_VIEW: "/nuggets-tag-view",
  PRIVACY_POLICY: "/privacy-policy",
  PRIVACY_POLICY_VIEW: "/privacy-policy-view",
  TERMS_OF_USE_POLICY: "/terms-of-use-policy",
  TERMS_OF_USE_POLICY_VIEW: "/terms-of-use-policy-view",
};

export const ROUTING_CONSTANT_SUB_PATH = {
  USER: ROUTING_CONSTANT.USER,
  USER_SLUG: `${ROUTING_CONSTANT.USER}/:slug`,
  USER_HARDWARE_SPECIFIC_SLUG: `${ROUTING_CONSTANT.USER_HARDWARE_SPECIFIC}/:slug`,
  USER_METRIC_SPECIFIC_SLUG: `${ROUTING_CONSTANT.USER_METRIC_SPECIFIC}/:slug`,
  USER_MEDICAL_SPECIFIC_SLUG: `${ROUTING_CONSTANT.USER_MEDICAL_SPECIFIC}/:slug`,
  USER_PROFILE_CATEGORY: ROUTING_CONSTANT.USER_PROFILE_CATEGORY,
  USER_PROFILE_CATEGORY_SLUG: `${ROUTING_CONSTANT.USER_PROFILE_CATEGORY}/:slug`,
  USER_PROFILE_SUB_CATEGORY: ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY,
  USER_PROFILE_SUB_CATEGORY_SLUG: `${ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY}/:slug`,
  USER_PROFILE_QUESTION_SLUG: `${ROUTING_CONSTANT.USER_PROFILE_QUESTION}/:slug`,
  USER_PROFILE_QUESTION: ROUTING_CONSTANT.USER_PROFILE_QUESTION,
  CATEGORY: ROUTING_CONSTANT.CATEGORY,
  CATEGORY_SLUG: `${ROUTING_CONSTANT.CATEGORY}/:slug`,
  SUB_CATEGORY: ROUTING_CONSTANT.SUB_CATEGORY,
  SUB_CATEGORY_SLUG: `${ROUTING_CONSTANT.SUB_CATEGORY}/:slug`,
  MODEL: ROUTING_CONSTANT.MODEL,
  MODEL_SLUG: `${ROUTING_CONSTANT.MODEL}/:slug`,
  PRODUCT: ROUTING_CONSTANT.PRODUCT,
  PRODUCT_SLUG: `${ROUTING_CONSTANT.PRODUCT}/:slug`,
  SKU: ROUTING_CONSTANT.SKU,
  SKU_SLUG: `${ROUTING_CONSTANT.SKU}/:slug`,
  INGREDIENT: ROUTING_CONSTANT.INGREDIENT,
  INGREDIENT_SLUG: `${ROUTING_CONSTANT.INGREDIENT}/:slug`,
  METRICS: ROUTING_CONSTANT.SUB_METRIC,
  METRICS_SLUG: `${ROUTING_CONSTANT.SUB_METRIC}/:slug`,
  MARKETING: ROUTING_CONSTANT.MARKETING,
  MARKETING_SLUG: `${ROUTING_CONSTANT.MARKETING}/:slug`,
  DOSAGE_ASSISTANCE: ROUTING_CONSTANT.DOSAGE_ASSISTANCE,
  DOSAGE_ASSISTANCE_SLUG: `${ROUTING_CONSTANT.DOSAGE_ASSISTANCE}/:slug`,
  BENEFIT: ROUTING_CONSTANT.BENEFIT,
  BENEFIT_SLUG: `${ROUTING_CONSTANT.BENEFIT}/:slug`,
  FAQ: ROUTING_CONSTANT.FAQ,
  FAQ_SLUG: `${ROUTING_CONSTANT.FAQ}/:slug`,
  KEYWORD: ROUTING_CONSTANT.KEYWORD,
  KEYWORD_SLUG: `${ROUTING_CONSTANT.KEYWORD}/:slug`,
  HOW_IT_WORK: ROUTING_CONSTANT.HOW_IT_WORK,
  HOW_IT_WORK_SLUG: `${ROUTING_CONSTANT.HOW_IT_WORK}/:slug`,
  MOBILE_SCREEN: ROUTING_CONSTANT.MOBILE_SCREEN,
  MOBILE_SCREEN_SLUG: `${ROUTING_CONSTANT.MOBILE_SCREEN}/:slug`,
  CLASS: ROUTING_CONSTANT.CLASS,
  CLASS_SLUG: `${ROUTING_CONSTANT.CLASS}/:slug`,
  CHAPTER: ROUTING_CONSTANT.CHAPTER,
  CHAPTER_SLUG: `${ROUTING_CONSTANT.CHAPTER}/:slug`,
  COURSE: ROUTING_CONSTANT.COURSE,
  COURSE_SLUG: `${ROUTING_CONSTANT.COURSE}/:slug`,
  COURSE_FAQ: ROUTING_CONSTANT.COURSE_FAQ,
  COURSE_FAQ_SLUG: `${ROUTING_CONSTANT.COURSE_FAQ}/:slug`,
  MASTER: ROUTING_CONSTANT.MASTER,
  MASTER_SLUG: `${ROUTING_CONSTANT.MASTER}/:slug`,
  TRAINER: ROUTING_CONSTANT.TRAINER,
  TRAINER_SLUG: `${ROUTING_CONSTANT.TRAINER}/:slug`,
  PROMO_CODE: ROUTING_CONSTANT.PROMO_CODE,
  PROMO_CODE_SLUG: `${ROUTING_CONSTANT.PROMO_CODE}/:slug`,
  DELIVERY_OPTIONS: ROUTING_CONSTANT.DELIVERY_OPTIONS,
  DELIVERY_OPTIONS_SLUG: `${ROUTING_CONSTANT.DELIVERY_OPTIONS}/:slug`,
  BOT: ROUTING_CONSTANT.BOT,
  BOT_SLUG: `${ROUTING_CONSTANT.BOT}/:slug`,
  USER_DETAIL_SCREEN: ROUTING_CONSTANT.USER_DETAIL_SCREEN,
  USER_DETAIL_SCREEN_SLUG: `${ROUTING_CONSTANT.USER_DETAIL_SCREEN}/:slug`,
  INTERESTS: ROUTING_CONSTANT.INTERESTS,
  INTERESTS_SLUG: `${ROUTING_CONSTANT.INTERESTS}/:slug`,
  INTERESTS_QUESTIONS: ROUTING_CONSTANT.INTERESTS_QUESTIONS,
  INTERESTS_QUESTIONS_SLUG: `${ROUTING_CONSTANT.INTERESTS_QUESTIONS}/:slug`,
  LEVEL_ONE_MAPPING: ROUTING_CONSTANT.LEVEL_ONE_MAPPING,
  LEVEL_ONE_MAPPING_SLUG: `${ROUTING_CONSTANT.LEVEL_ONE_MAPPING}/:slug`,
  LEVEL_ZERO_MAPPING: ROUTING_CONSTANT.LEVEL_ZERO_MAPPING,
  LEVEL_ZERO_MAPPING_SLUG: `${ROUTING_CONSTANT.LEVEL_ZERO_MAPPING}/:slug`,
  GOAL: ROUTING_CONSTANT.GOAL,
  GOAL_SLUG: `${ROUTING_CONSTANT.GOAL}/:slug`,
  LAB_TEST: ROUTING_CONSTANT.LAB_TEST,
  LAB_TEST_SLUG: `${ROUTING_CONSTANT.LAB_TEST}/:slug`,
  DEVICE: ROUTING_CONSTANT.DEVICE,
  DEVICE_SLUG: `${ROUTING_CONSTANT.DEVICE}/:slug`,
  ASSESSMENT: ROUTING_CONSTANT.ASSESSMENT,
  ASSESSMENT_SLUG: `${ROUTING_CONSTANT.ASSESSMENT}/:slug`,
  ASSESSMENT_QUESTION: ROUTING_CONSTANT.ASSESSMENT_QUESTION,
  ASSESSMENT_QUESTION_SLUG: `${ROUTING_CONSTANT.ASSESSMENT_QUESTION}/:slug`,
  RECOMMENDATION_RULE: ROUTING_CONSTANT.RECOMMENDATION_RULE,
  RECOMMENDATION_RULE_SLUG: `${ROUTING_CONSTANT.RECOMMENDATION_RULE}/:slug`,
  RECIPE: ROUTING_CONSTANT.RECIPE,
  RECIPE_SLUG: `${ROUTING_CONSTANT.RECIPE}/:slug`,
  RECIPE_BUNDLE: ROUTING_CONSTANT.RECIPE_BUNDLE,
  RECIPE_BUNDLE_SLUG: `${ROUTING_CONSTANT.RECIPE_BUNDLE}/:slug`,
  NUGGETS: ROUTING_CONSTANT.NUGGETS,
  NUGGETS_SLUG: `${ROUTING_CONSTANT.NUGGETS}/:slug`,
  NUGGETS_TAG: ROUTING_CONSTANT.NUGGETS_TAG,
  NUGGETS_TAG_SLUG: `${ROUTING_CONSTANT.NUGGETS_TAG}/:slug`,
  PRIVACY_POLICY: ROUTING_CONSTANT.PRIVACY_POLICY,
  PRIVACY_POLICY_SLUG: `${ROUTING_CONSTANT.PRIVACY_POLICY}/:slug`,
  TERMS_OF_USE_POLICY: ROUTING_CONSTANT.TERMS_OF_USE_POLICY,
  TERMS_OF_USE_POLICY_SLUG: `${ROUTING_CONSTANT.TERMS_OF_USE_POLICY}/:slug`,
};

export function isValidHttpUrl(string: any) {
  var res = string.match(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  );
  return res !== null;
}
export function isValidVideoUrl(string: any) {
  const directLinkPattern = /\.(mp4|mp3)$/i;
  const driveLinkPattern =
    /^https:\/\/drive\.google\.com\/file\/d\/[a-zA-Z0-9_-]+/i;

  return directLinkPattern.test(string) || driveLinkPattern.test(string);
}

export function isValidUrl() {
  return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
}
export const SHOW_CONFIRM_DIALOG = (message: any, callback: any) => {
  Swal.fire({
    iconColor: "black",
    className: "alert",
    title: message,
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#c12424",
    cancelButtonColor: "black",
    confirmButtonText: "Yes, delete it!",
    width: "30rem",
    height: "18rem",
  } as SweetAlertOptions).then((result: any) => {
    if (result.value) {
      callback(result.value);
    } else {
      callback(false);
    }
  });
};
