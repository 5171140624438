import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Select from "react-select";
import { CronjobService } from "../../../services/cronJob.service";
import { BotService } from "../../../services/bot.service";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { UserService } from "../../../services/user.service";
import { Switch } from "@material-ui/core";

const AddEditCronJob = () => {
    const { metricData, setMetricData, usersData, setUsersData }: any =
        useContext(DataContext);
    const [checked, setChecked] = useState(false);
    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const navigate = useNavigate();
    const [tab, setTab] = useState("level1");
    const [value, setValue] = React.useState(0);
    const [selectedMetricArr, setSelectedMetricArr] = useState<any>([]);
    const [selectedMetricName, setSelectedMetricName] = useState([]);
    const [selectedMetricId, setSelectedMetricId] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUserName, setSelectedUserName] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState<any>([]);
    const [selectedUserIdArr, setSelectedUserIdArr] = useState<any>([]);
    const [MetricAllData, setMetricAllData] = useState({
        metrics: "",
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { handleSubmit } = useFormik({
        initialValues: { ...MetricAllData },

        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = await CronjobService.runLevelTwoMetric({
                    ...values,
                    metrics: selectedMetricArr,
                });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    setSelectedMetricName([]);
                    navigate(ROUTING_CONSTANT.CRON_JOB_VIEW);
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const data = selectedUserName
            ? selectedUserName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];

        setSelectedUserId(data);
        if (usersData.length === 0) {
            getAllUsersCronJob();
        }
    }, [selectedUserName]);

    const getAllUsersCronJob = async () => {
        try {
            let data: any = await UserService.getAllUsersCronJob();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.users;
                setUsersData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const handleSave = () => {
        handleSubmit();
        setIsInvalidForm(true);
        setSelectedMetricName([]);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    useEffect(() => {
        const data = selectedMetricName
            ? selectedMetricName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];

        setSelectedMetricId(data);
        if (metricData?.length === 0) {
            getAllMetricName();
        }
    }, [selectedMetricName]);

    const getAllMetricName = async () => {
        try {
            let data: any = await BotService.getAllMetricName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.metric;
                setMetricData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const runLevelOneMetric = async () => {
        try {
            setIsLoading(true);
            let payload;
            if (selectedUserIdArr.length > 0) {
                payload = {
                    userId: selectedUserIdArr,
                }
            }
            let data: any = await CronjobService.runLevelOneMetric(payload);
            setIsLoading(false);
            if (data && data.status === CONSTANT.SUCCESS) {
                setSelectedUserIdArr([]);
                setSelectedUserName([]);
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error(CONSTANT.ERROR_OCCURRED);
            setIsLoading(false);
        }
    };

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>Metric Cron Job</strong>
                    </h3>
                    <Paper>
                        <Tabs
                            value={value}
                            textColor="primary"
                            indicatorColor="primary"
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                setTab(newValue === 0 ? "level1" : "level2" ? "level2" : "level3");
                            }}
                        >
                            <Tab label="Level 2 Metric" />
                            <Tab label="Level 1 Metric" />
                            <Tab label="Level 3 Metric" />
                        </Tabs>
                    </Paper>
                    {tab === "level1" && (
                        <form onSubmit={handleSubmit} className="mt-4">
                            <div className="row g-4 ">
                                <div className="col-sm-12 col-md-6" key="benefits">
                                    <label className="form-labels">Select Metric Cron Job*</label>
                                    <FormGroup id="metrics">
                                        <Select
                                            styles={customStyles}
                                            onChange={(selectedOptions: any) => {
                                                const metrics = selectedOptions.map((metric: any) => ({
                                                    id: metric.value,
                                                    label: metric.label,
                                                }));
                                                const metric = selectedOptions.map(
                                                    (metric: any) => metric.value
                                                );
                                                setSelectedMetricArr(metric);
                                                setSelectedMetricName(metrics);
                                            }}
                                            options={
                                                metricData &&
                                                metricData.map((metric: any) => ({
                                                    value: metric._id,
                                                    label: metric.metricName,
                                                }))
                                            }
                                            value={selectedMetricId}
                                            placeholder="Select Metrics"
                                            className={`${isInvalidForm && selectedMetricId === 0
                                                ? "border border-danger"
                                                : ""
                                                }`}
                                            isSearchable={true}
                                            isMulti
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-sm-12 col-md-6 mt-5">
                                    {selectedMetricName.length > 0 ? (
                                        <div className="">
                                            <Button
                                                type="button"
                                                color="danger"
                                                className="w-25 mt-2 cancel-btn float-start"
                                                onClick={handleSave}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </form>
                    )}
                    {tab === "level2" && (
                        <>
                            <div className="m-2">
                                <Switch
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color='primary'
                                />
                                {!checked ? <b>All Users</b> : <b>Select Users</b>}
                            </div>
                            <div className="col-sm-12 col-md-6 mt-2">
                                {checked && <div className="col-sm-12" key="user">

                                    <FormGroup id="user">
                                        <Select
                                            styles={customStyles}
                                            onChange={(selectedOptions: any) => {
                                                const user = selectedOptions.map((users: any) => ({
                                                    id: users.value,
                                                    label: users.label,
                                                }));

                                                const users = selectedOptions.map(
                                                    (user: any) => user.value
                                                );
                                                setSelectedUserIdArr(users);

                                                setSelectedUserName(user);
                                            }}
                                            options={
                                                usersData &&
                                                usersData
                                                    .map((user: any) => ({
                                                        value: user._id,
                                                        label: `${user.firstName} - ${user.email}`,
                                                    }))
                                            }
                                            placeholder="Select Users"
                                            value={selectedUserId}
                                            isSearchable={true}
                                            isMulti
                                            className={`${selectedUserId.length === 0
                                                ? "border"
                                                : ""
                                                }`}
                                        />
                                    </FormGroup>
                                </div>}
                                <div className="">
                                    {checked && selectedUserName.length > 0 &&
                                        <Button
                                            type="button"
                                            color="danger"
                                            className="w-25 mt-2 cancel-btn float-start"
                                            onClick={runLevelOneMetric}
                                        >
                                            Run Cron job
                                        </Button>
                                    }
                                    {!checked &&
                                        <Button
                                            type="button"
                                            color="danger"
                                            className="w-25 mt-2 cancel-btn float-start"
                                            onClick={runLevelOneMetric}
                                        >
                                            Run Cron job
                                        </Button>
                                    }
                                </div>
                            </div>
                        </>
                    )}
                    {tab === "level3" && (
                        <form onSubmit={handleSubmit} className="mt-4">
                            <div className="row g-4 ">
                                <div className="col-sm-12 col-md-6" key="benefits">
                                    <label className="form-labels">Select Metrics*</label>
                                    <FormGroup id="metrics">
                                        <Select
                                            styles={customStyles}
                                            onChange={(selectedOptions: any) => {
                                                const metrics = selectedOptions.map((metric: any) => ({
                                                    id: metric.value,
                                                    label: metric.label,
                                                }));
                                                const metric = selectedOptions.map(
                                                    (metric: any) => metric.value
                                                );
                                                setSelectedMetricArr(metric);
                                                setSelectedMetricName(metrics);
                                            }}
                                            options={
                                                metricData &&
                                                metricData.map((metric: any) => ({
                                                    value: metric._id,
                                                    label: metric.metricName,
                                                }))
                                            }
                                            value={selectedMetricId}
                                            placeholder="Select Metrics"
                                            className={`${isInvalidForm && selectedMetricId === 0
                                                ? "border border-danger"
                                                : ""
                                                }`}
                                            isSearchable={true}
                                            isMulti
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-sm-12 col-md-6 mt-5">
                                    {selectedMetricName.length > 0 ? (
                                        <div className="">
                                            <Button
                                                type="button"
                                                color="danger"
                                                className="w-25 mt-2 cancel-btn float-start"
                                                onClick={handleSave}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </form>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditCronJob;