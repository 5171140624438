import React, { useEffect, useState } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Localstorage,
} from "../../../core/auth.service";
import { CONSTANT } from "../../../core/static-constant";
import { toast } from "react-toastify";
import { UserService } from "../../../services/user.service";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import "../roleManagement/roleManagement.css";
import Page from "../../../layout/Page/Page";
import Button from "../../../components/bootstrap/Button";
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "../../../components/bootstrap/Modal";
import Loader from "../../Loader/loader";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
const FILE_DOWNLOAD_URL: any = process.env.REACT_APP_FILE_DOWNLOAD_URL;

const MedicalSpecific = () => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [MetricData, setMetricData] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { state } = useLocation();
    const UserEmail = state?.userEmail;
    const [pdfDataByIdResult, setPdfDataByIdResult] = useState<any>();
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);
    const MetricUserId = Localstorage(CONSTANT.GET, CONSTANT.MEDICAL_SPECIFIC_USER_ID);

    const navigate = useNavigate();
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleClickDownload = async (fileName: any) => {
        const URL: any = FILE_DOWNLOAD_URL + fileName;
        window.open(URL, '_blank', 'noreferrer');
    }

    useEffect(() => {
        if (!isInitialized) {
            setIsInitialized(true);
        } else {
            let obj = {
                userId: MetricUserId,
            };
            getAllMedicalReportByUser(obj);
        }
    }, [isInitialized]);

    const getAllMedicalReportByUser = async (obj: any) => {
        try {
            setShowLoading(true);
            let data: any = await UserService.getAllMedicalReportByUser(obj);
            setShowLoading(false);
            if (data && data?.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.medicalDetails?.clinicalDocuments || {};
                setMetricData(responseData);
            } else {
                setMetricData([]);
                if (
                    data.message === CONSTANT.AUTHENTICATION_FAIL ||
                    data.statusCode === 403
                ) {
                    navigate("/auth-pages/login");
                    Localstorage(CONSTANT.REMOVE, CONSTANT.AUTH_TOKEN);
                }
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
            setShowLoading(false);
            setMetricData([]);
        }
    };

    const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            search: "",
        },
        onSubmit: async (values, newPage: any) => {
            try {
                let obj = {
                    // limit: perPage,
                    // pageCount: 1,
                    search: values.search,
                    userId: MetricUserId,
                    // fields: ["trainerName", "trainerImage1", "trainerIntroTitle", "trainerIntroLongDesc"],
                };

                await getAllMedicalReportByUser(obj);
                // setCurrentPage(1);
            } catch (error: any) {
                toast.error(error.response.data.error);
            }

        },
    });
    useEffect(() => {
        if (values.search) {
            setIsSearchDisabled(false);
        } else {
            setIsSearchDisabled(true);
        }
    }, [values.search]);

    const handleClearFilter = () => {
        setFieldValue("search", "");
        handleSubmit();
    }
    const getClinicalReportByUserId = async (pdfId: any) => {
        try {
            setShowLoading(true);
            let data: any = await UserService.getClinicalReportByUserId(pdfId);
            setShowLoading(false);
            if (data && data?.status === CONSTANT.SUCCESS) {
                let obj = {
                    userId: MetricUserId,
                    search: values.search,
                };
                getAllMedicalReportByUser(obj)
            } else {
                if (
                    data.message === CONSTANT.AUTHENTICATION_FAIL ||
                    data.statusCode === 403
                ) {
                    navigate("/auth-pages/login");
                    Localstorage(CONSTANT.REMOVE, CONSTANT.AUTH_TOKEN);
                }
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
            setShowLoading(false);
        }
    };

    const getMetricDetailByPdfId = async (pdfId: any) => {
        try {
            setShowLoading(true);
            let data: any = await UserService.getMetricDetailByPdfId(pdfId);
            setShowLoading(false);
            if (data && data?.status === CONSTANT.SUCCESS) {
                const pdfData = data?.data?.metricData || {};
                setPdfDataByIdResult(pdfData);
            } else {
                if (
                    data.message === CONSTANT.AUTHENTICATION_FAIL ||
                    data.statusCode === 403
                ) {
                    navigate("/auth-pages/login");
                    Localstorage(CONSTANT.REMOVE, CONSTANT.AUTH_TOKEN);
                }
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
            setShowLoading(false);
        }
        setIsModalOpen(!isModalOpen);
    };

    const renderTableRows = (data: any) => {
        return Object.keys(data).map((key, index) => {
            const value = data[key];
            if (typeof value === 'object' && value !== null) {
                return (
                    <React.Fragment key={index}>
                        <tr>
                            <td className="col-sm-9"><strong>{key}:</strong></td>
                            <td className="col-sm-3"></td>
                        </tr>
                        {renderTableRows(value)}
                    </React.Fragment>
                );
            } else {
                return (
                    <tr key={index}>
                        <td className="col-sm-9"><p>{key}:</p></td>
                        <td className="col-sm-3 text-end">{value}</td>
                    </tr>
                );
            }
        });
    };

    const renderData = (data: any) => {
        if (Array.isArray(data)) {
            return data.map((item, index) => (
                <div key={index}>
                    <table className="table">
                        <tbody>
                            {renderTableRows(item)}
                        </tbody>
                    </table>
                </div>
            ));
        } else if (typeof data === 'object' && data !== null) {
            return (
                <table className="table">
                    <tbody>
                        {renderTableRows(data)}
                    </tbody>
                </table>
            );
        } else {
            return null; // In case of unexpected data types
        }
    };
    return (
        <>
            <PageWrapper>
                <Page container="fluid">
                    {showLoading && (
                        <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                            <Loader />
                        </div>
                    )}
                    <div className="">
                        <Card stretch>
                            <h3 className="text-center mt-3 mb-5 text-primary">
                                <strong>Medical Data</strong>
                            </h3>

                            <div className="d-flex justify-content-between align-items-center px-5 flex-wrap">
                                <p className="fw-bolder">
                                    UserEmail: {UserEmail}
                                </p>
                                <form onSubmit={handleSubmit}>
                                    <div className="d-flex gap-2 align-items-center search-div col-sm-12">
                                        <FormGroup label="Search PDF by PdfId..." isFloating>
                                            <Input
                                                id="trainer"
                                                className="order col-sm-12"
                                                placeholder="Search PDF by PdfId"
                                                name="search"
                                                type='text'
                                                value={values.search}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                        <div className="search-btn d-flex gap-2">
                                            <div>
                                                <Button
                                                    type="button"
                                                    className="btn btn-info col-sm-12"
                                                    onClick={handleSubmit}
                                                    isDisable={isSearchDisabled}
                                                >
                                                    Search
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    type="button"
                                                    className="btn-danger text-nowrap col-sm-12"
                                                    onClick={handleClearFilter}
                                                    isDisable={isSearchDisabled}
                                                >
                                                    Clear Filter
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div>
                                    <Button
                                        type="button"
                                        className="btn-dark text-nowrap mt-2"
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>
                            <CardBody className="table-responsive">
                                <table className="table table-modern table-hover">
                                    <thead>
                                        <tr>
                                            <th>Pdf Name</th>
                                            <th>Pdf Id</th>
                                            <th className="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    {showLoading ? (
                                        <>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="spinner-border" role="status">
                                                                <span className="visually-hidden">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                    ) : MetricData.length === 0 ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="text-center fw-bold">
                                                        No data Found
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {MetricData &&
                                                MetricData?.flat().map((ele: any, i: any) => {
                                                    return (
                                                        <tr key={i}>
                                                            <>
                                                                <th className="text-muted text-capitalize">
                                                                    <span dangerouslySetInnerHTML={{ __html: ele?.originalName || "N/A" }} />
                                                                </th>
                                                                <th className="text-muted text-capitalize">
                                                                    <span dangerouslySetInnerHTML={{ __html: ele?.pdfId || "N/A" }} />
                                                                </th>
                                                                <th className="text-end text-capitalize">
                                                                    <Button
                                                                        icon="CloudDownload"
                                                                        type="button"
                                                                        className="btn-dark text-nowrap mt-2 mx-2"
                                                                        onClick={() => handleClickDownload(ele?.fileName)}
                                                                    >
                                                                    </Button>
                                                                    {ele?.isMetricGenerated !== true ?
                                                                        <Button
                                                                            type="button"
                                                                            className="btn-dark text-nowrap mt-2"
                                                                            onClick={() => {
                                                                                const data = {
                                                                                    pdfId: ele?.pdfId,
                                                                                    userId: state?.userId
                                                                                };
                                                                                getClinicalReportByUserId(data);
                                                                            }}
                                                                        >
                                                                            Generate Metric
                                                                        </Button> :
                                                                        <Button
                                                                            icon="Visibility"
                                                                            className="eye-icon"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="bottom"
                                                                            title="View Details"
                                                                            onClick={() => {
                                                                                const data = {
                                                                                    pdfId: ele?.pdfId,
                                                                                    userId: state?.userId
                                                                                }
                                                                                getMetricDetailByPdfId(data);
                                                                            }}
                                                                        ></Button>
                                                                    }
                                                                </th>
                                                            </>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    )}
                                </table>
                            </CardBody>
                            {isModalOpen && (
                                <Modal
                                    setIsOpen={closeModal}
                                    isOpen={isModalOpen}
                                    toggle={closeModal}
                                >
                                    <ModalHeader className="headertext d-flex justify-content-between fw-bolder">
                                        <>
                                            <div className="mx-3 my-0">
                                                <p className="text-uppercase fw-bolder">
                                                    Metric Data
                                                </p>
                                            </div>
                                        </>
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className="container">
                                            {pdfDataByIdResult && Object.keys(pdfDataByIdResult).map((ele, index) => {
                                                const data = pdfDataByIdResult[ele];
                                                return (
                                                    <div key={ele}>
                                                        {Array.isArray(data) && data.length > 0 ? (
                                                            renderData(data)
                                                        ) : (typeof data === 'object' && data !== null) ? (
                                                            renderData(data)
                                                        ) : (
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="col-sm-9"><strong>{ele} :</strong></td>
                                                                        <td className="col-sm-3 text-end"><p>{data !== undefined ? data : '0'}</p></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={closeModal}>
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            )}
                        </Card>
                    </div>
                </Page>
            </PageWrapper>
        </>
    );
};

export default MedicalSpecific;
