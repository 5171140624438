import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { RecipeService } from "../../../services";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { isValidHttpUrl, isValidUrl, ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import Select from "react-select";
import { BotService } from "../../../services/bot.service";
import { SelectChangeEvent } from '@mui/material/Select';
import { Select as MuiSelect, Checkbox, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, TextField, Box, FormHelperText } from '@mui/material';
import Textarea from "../../../components/bootstrap/forms/Textarea";

const AddEditRecipe = () => {
    const { recipeData, setRecipeData, setMetricData, metricData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [recipeId, setRecipeId] = useState("");
    const [imageArray, setImageArray] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [recipeName, setRecipeName] = React.useState<string[]>([]);
    const [getRecipeName, setGetRecipeName] = React.useState<string[]>([]);

    const [cookingDeviceName, setCookingDeviceName] = React.useState<string[]>([]);
    const [getCookingDeviceName, setGetCookingDeviceName] = React.useState<string[]>([]);

    const [selectedMetricName, setSelectedMetricName] = useState([]);
    const [selectedMetricId, setSelectedMetricId] = useState<any>([]);
    const [selectedMetricIdArr, setSelectedMetricIdArr] = useState<any>([]);
    const [checkedValues, setCheckedValues] = useState<any>([]);
    const [initialTasteMeter, setInitialTasteMeter] = React.useState<string[]>([]);

    const [recipeAllData, setRecipeAllData] = useState({
        recipeName: "",
        recipeShortDescription: "",
        caloriesServing: "",
        recipeImage: [""],
        ingredients: [{ name: "", description: "" }],
        cookingInstructions: "",
        chefTips: "",
        pairingSuggestions: "",
        makeItYourOwn: "",
        whyRecipe: [{ name: "", description: "" }],
        metricId: "",
        cookingTime: "",
        dietaryPreference: "",
        cookingComplexity: "",
        cuisineType: "",
        allergensExcluded: "",
        cookingDevices: "",
        tasteMeter: "",
        nutritionInfo: [{ type: "", gms: "" }],
        mealType: ""
    });

    useEffect(() => {
        setRecipeName(getRecipeName);
    }, [getRecipeName]);

    useEffect(() => {
        setCookingDeviceName(getCookingDeviceName);
    }, [getCookingDeviceName]);

    useEffect(() => {
        if (initialTasteMeter && initialTasteMeter.length > 0) {
            const tastes = CONSTANT.TASTES_ARRAY.reduce((acc: { [key: string]: string }, taste) => {
                acc[taste] = "";
                return acc;
            }, {});

            initialTasteMeter.forEach(tasteRank => {
                const [taste, rank] = tasteRank.split(' ');
                if (taste in tastes) {
                    tastes[taste] = rank.split('/')[0];
                }
            });

            setSelectedTastes(tastes);
            setDisplayedSelection(initialTasteMeter.join(', '));
        }
    }, [initialTasteMeter]);

    const TasteOption = [
        { sourceName: CONSTANT.TASTES.SPICY, value: CONSTANT.TASTES.SPICY, label: CONSTANT.TASTES.SPICY, _id: '1' },
        { sourceName: CONSTANT.TASTES.SWEET, value: CONSTANT.TASTES.SWEET, label: CONSTANT.TASTES.SWEET, _id: '2' },
        { sourceName: CONSTANT.TASTES.SOUR, value: CONSTANT.TASTES.SOUR, label: CONSTANT.TASTES.SOUR, _id: '3' },
        { sourceName: CONSTANT.TASTES.BITTER, value: CONSTANT.TASTES.BITTER, label: CONSTANT.TASTES.BITTER, _id: '4' },
        { sourceName: CONSTANT.TASTES.SALTY, value: CONSTANT.TASTES.SALTY, label: CONSTANT.TASTES.SALTY, _id: '5' },
        { sourceName: CONSTANT.TASTES.SAVORY, value: CONSTANT.TASTES.SAVORY, label: CONSTANT.TASTES.SAVORY, _id: '6' },
    ];

    const initialTastes = TasteOption.reduce((acc: any, option: any) => {
        acc[option.label] = '';
        return acc;
    }, {});

    const [selectedTastes, setSelectedTastes] = useState<{ [key: string]: string }>(initialTastes);
    const [displayedSelection, setDisplayedSelection] = useState<string>('');

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, taste: string) => {
        const isChecked = event.target.checked;

        setSelectedTastes(prevState => ({
            ...prevState,
            [taste]: isChecked ? '0' : ''
        }));
    };

    const handleChangeRank = (event: React.ChangeEvent<HTMLInputElement>, taste: string) => {
        const value = event.target.value;
        const numericValue = parseInt(value, 10);

        if (numericValue <= 10) {
            setSelectedTastes(prevState => ({
                ...prevState,
                [taste]: value
            })
            );
        } else {
            return;
        }
    };

    const handleDropdownClose = () => {
        let displayText = '';
        Object.keys(selectedTastes).forEach(taste => {
            const rank = selectedTastes[taste];
            if (rank !== '' && rank !== '0') {
                displayText += `${taste}: ${rank}/10, `;
            }
        });
        setDisplayedSelection(displayText.slice(0, -2));
    };

    const handleChangeRecipe = (event: SelectChangeEvent<string[]>) => {
        const { target: { value } } = event;
        setRecipeName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const renderSelectedValues = (selected: string[]) => {
        return selected.map((value) => {
            const option = RecipeOption.find((opt) => opt.value === value);
            return option ? option.label : value;
        }).join(', ');
    };

    const handleChangeCookingDevice = (event: SelectChangeEvent<string[]>) => {
        const { target: { value } } = event;
        setCookingDeviceName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const renderSelectedCookingDeviceValues = (selected: string[]) => {
        return selected.map((value) => {
            const option = CookingDeviceOption.find((opt) => opt.value === value);
            return option ? option.label : value;
        }).join(', ');
    };

    const handleCheckboxChange = (event: any) => {
        const { value, checked } = event.target;
        if (checked) {
            setCheckedValues((prevChecked: any) => [...prevChecked, value]);
        } else {
            setCheckedValues((prevChecked: any) =>
                prevChecked.filter((mealType: any) => mealType !== value)
            );
        }
    };

    const MealOption = [
        { sourceName: CONSTANT.MEALS.BREAKFAST, value: CONSTANT.MEALS.BREAKFAST, label: CONSTANT.MEALS.BREAKFAST, _id: '1' },
        { sourceName: CONSTANT.MEALS.LUNCH, value: CONSTANT.MEALS.LUNCH, label: CONSTANT.MEALS.LUNCH, _id: '2' },
        { sourceName: CONSTANT.MEALS.DINNER, value: CONSTANT.MEALS.DINNER, label: CONSTANT.MEALS.DINNER, _id: '3' },
    ];

    const RecipeOption = [
        { sourceName: CONSTANT.RECIPES.DIETARY, value: CONSTANT.RECIPES.DIETARY, label: CONSTANT.RECIPES.DIETARY, _id: '1' },
        { sourceName: CONSTANT.RECIPES.APPETIZERS, value: CONSTANT.RECIPES.APPETIZERS, label: CONSTANT.RECIPES.APPETIZERS, _id: '2' },
        { sourceName: CONSTANT.RECIPES.DESSERTS, value: CONSTANT.RECIPES.DESSERTS, label: CONSTANT.RECIPES.DESSERTS, _id: '3' },
        { sourceName: CONSTANT.RECIPES.SNACKS, value: CONSTANT.RECIPES.SNACKS, label: CONSTANT.RECIPES.SNACKS, _id: '4' },
        { sourceName: CONSTANT.RECIPES.SOUP, value: CONSTANT.RECIPES.SOUP, label: CONSTANT.RECIPES.SOUP, _id: '5' },
        { sourceName: CONSTANT.RECIPES.MAINCOURSE, value: CONSTANT.RECIPES.MAINCOURSE, label: CONSTANT.RECIPES.MAINCOURSE, _id: '6' },
    ];

    const CookingDeviceOption = [
        { sourceName: CONSTANT.COOKINGDEVICE.COOKER, value: CONSTANT.COOKINGDEVICE.COOKER, label: CONSTANT.COOKINGDEVICE.COOKER, _id: '1' },
        { sourceName: CONSTANT.COOKINGDEVICE.STOVE, value: CONSTANT.COOKINGDEVICE.STOVE, label: CONSTANT.COOKINGDEVICE.STOVE, _id: '2' },
        { sourceName: CONSTANT.COOKINGDEVICE.TOASTER, value: CONSTANT.COOKINGDEVICE.TOASTER, label: CONSTANT.COOKINGDEVICE.TOASTER, _id: '3' },
        { sourceName: CONSTANT.COOKINGDEVICE.MIXER, value: CONSTANT.COOKINGDEVICE.MIXER, label: CONSTANT.COOKINGDEVICE.MIXER, _id: '4' },
        { sourceName: CONSTANT.COOKINGDEVICE.STEAMER, value: CONSTANT.COOKINGDEVICE.STEAMER, label: CONSTANT.COOKINGDEVICE.STEAMER, _id: '5' },
    ];

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const validationSchema = yup.object().shape({
        recipeName: yup.string().required(" required"),
        recipeShortDescription: yup.string().required(" required"),
        // ingredients: yup.string().required(" required"),
        // recipeImage: yup.string().required("required"),
        caloriesServing: yup.string().required("required"),
        cookingTime: yup.string().required("required"),
        // dietaryPreference: yup.string().required("required"),
        allergensExcluded: yup.string().required("required"),
        // cookingDevices: yup.string().required("required"),
        cookingInstructions: yup.string().required("required"),
    });

    const handleAddClick = () => {
        if (values.recipeImage[0] !== "" && isValidHttpUrl(values.recipeImage)) {
            setImageArray((prevArray: any) => [...prevArray, values.recipeImage]);
            setValues({ ...values, recipeImage: [""] });
        } else {
            toast.error(CONSTANT.VALID_IMAGE);
        }
    };

    const handleRemoveClick = (index: any) => {
        setImageArray((prevArray: any) => {
            const newArray = [...prevArray];
            newArray.splice(index, 1);
            return newArray;
        });
    };

    const handleAddOption = () => {
        const newOption = { name: "", description: "" };
        if (values.whyRecipe.every((option) => option.name !== "" && option.description !== "")) {
            setValues((prevValues) => ({
                ...prevValues,
                whyRecipe: [...prevValues.whyRecipe, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveOption = (index: any) => {
        setValues((prevValues) => {
            const updatedOptions = [...prevValues.whyRecipe];
            updatedOptions.splice(index, 1);
            return { ...prevValues, whyRecipe: updatedOptions };
        });
    };

    const handleAddNutritionOption = () => {
        const newOption = { type: "", gms: "" };
        if (values.nutritionInfo.every((option) => option.type !== "" && option.gms !== "")) {
            setValues((prevValues) => ({
                ...prevValues,
                nutritionInfo: [...prevValues.nutritionInfo, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveNutritionOption = (index: any) => {
        setValues((prevValues) => {
            const updatedOptions = [...prevValues.nutritionInfo];
            updatedOptions.splice(index, 1);
            return { ...prevValues, nutritionInfo: updatedOptions };
        });
    };

    const handleAddIngredients = () => {
        const newOption = { name: "", description: "" };
        if (values.ingredients.every((option) => option.name !== "" && option.description !== "")) {
            setValues((prevValues) => ({
                ...prevValues,
                ingredients: [...prevValues.ingredients, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveIngredients = (index: any) => {
        setValues((prevValues) => {
            const updatedOptions = [...prevValues.ingredients];
            updatedOptions.splice(index, 1);
            return { ...prevValues, ingredients: updatedOptions };
        });
    };

    useEffect(() => {
        if (metricData?.length === 0) {
            getAllMetricName();
        }
    }, []);

    useEffect(() => {
        const data = selectedMetricName
            ? selectedMetricName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];

        setSelectedMetricId(data);
    }, [selectedMetricName]);

    const getAllMetricName = async () => {
        try {
            let data: any = await BotService.getAllMetricName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.metric;
                setMetricData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...recipeAllData, recipeImage: [""] },
        validationSchema,
        onSubmit: async (values, action) => {
            const filteredImageArray = imageArray.filter(
                (image: any) => image.trim() !== ""
            );
            const filteredOptions = values.nutritionInfo.filter((option: any) => option.type !== "" && option.gms !== "");
            const filteredIngredients = values.ingredients.filter((option: any) => option.name !== "" && option.description !== "");
            try {
                if (!values.whyRecipe || values.whyRecipe.length === 0) {
                    toast.error("Options are required.");
                    setIsLoading(false);
                    return;
                }
                for (const option of values.whyRecipe) {
                    if (!option.name || !option.description) {
                        toast.error("Options are required.");
                        setIsLoading(false);
                        return;
                    }
                }
                let tasteMeter: string[] = [];
                Object.keys(selectedTastes).forEach(taste => {
                    const rank = selectedTastes[taste];
                    if (rank !== '' && rank !== '0') {
                        tasteMeter.push(`${taste} ${rank}/10`);
                    }
                });
                const payload = {
                    ...values,
                    recipeImage: filteredImageArray,
                    metricId: selectedMetricIdArr,
                    nutritionInfo: filteredOptions,
                    mealType: checkedValues,
                    dietaryPreference: recipeName,
                    cookingDevices: cookingDeviceName,
                    tasteMeter: tasteMeter,
                    ingredients: filteredIngredients
                }
                setIsLoading(true);
                const data = recipeId ? await RecipeService.updateRecipeById(recipeId, payload) : await RecipeService.createRecipe(payload);
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.RECIPE_VIEW);
                    const RecipeName = await RecipeService.getAllRecipeName();
                    setRecipeData(RecipeName?.data?.recipe);

                    const { _id, recipeName } = data.data;
                    if (recipeId) {
                        const existingIndex = recipeData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setRecipeData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, recipeName };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                console.error(error);
                toast.error(error.response.data.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setRecipeId(decryptData(params.id));
                getRecipeById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getRecipeById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await RecipeService.getRecipeById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setRecipeAllData(response?.data?.recipe);
                setValues({ ...response?.data?.recipe });
                setImageArray(response?.data?.recipe?.recipeImage);
                const selectedMetricNamesss = response?.data?.recipe?.metricId.map(
                    (metric: any) => ({
                        id: metric._id,
                        label: metric.metricName,
                    })
                );
                setSelectedMetricName(selectedMetricNamesss);
                const metricId = response?.data?.recipe?.metricId.map((metric: any) => metric._id);
                setSelectedMetricIdArr(metricId);
                if (response?.data?.recipe) {
                    const selectedMealFromData = response?.data?.recipe?.mealType || [];
                    setCheckedValues(selectedMealFromData);
                }
                setGetRecipeName(response?.data?.recipe.dietaryPreference.map((ele: any) => ele));
                setGetCookingDeviceName(response?.data?.recipe.cookingDevices.map((ele: any) => ele));
                setInitialTasteMeter(response?.data?.recipe.tasteMeter.map((ele: any) => ele))
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            toast.error(error.response.data.error);
        }
    };
    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.RECIPE_VIEW);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{recipeId ? CONSTANT.UPDATE : CONSTANT.ADD} Recipe</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Name*</label>
                                <FormGroup id="recipeName">
                                    <Input
                                        placeholder="Recipe Name"
                                        className={`${isInvalidForm && !values.recipeName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="recipeName"
                                        value={values.recipeName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.recipeName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Short Description*</label>
                                <FormGroup id="recipeShortDescription">
                                    <Input
                                        placeholder="Recipe Short Description"
                                        className={`${isInvalidForm && !values.recipeShortDescription
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="recipeShortDescription"
                                        value={values.recipeShortDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.recipeShortDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Calories/Serving*</label>
                                <FormGroup id="caloriesServing">
                                    <Input
                                        placeholder="Recipe Calories/Serving"
                                        className={`${isInvalidForm && !values.caloriesServing
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="caloriesServing"
                                        value={values.caloriesServing}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.caloriesServing && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6 ">
                                <label className="form-labels"> Recipe Images*</label>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormGroup id="recipeImage">
                                        <Input
                                            placeholder="Recipe Images"
                                            className={`${isInvalidForm && imageArray?.length === 0
                                                ? "border border-danger input-form"
                                                : "input-form"
                                                }`}
                                            name="recipeImage"
                                            value={values.recipeImage}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-plus-circle sku-icon cursor-pointer"
                                        viewBox="0 0 16 16"
                                        onClick={handleAddClick}
                                    >
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                </div>
                                {imageArray?.length > 0 && (
                                    <div className="mt-3 ">
                                        <FormGroup isFloating>
                                            <ul
                                                className="list-unstyled d-flex gap-2 flex-wrap 
"
                                            >
                                                {imageArray?.map((image: any, index: any) => (
                                                    <li key={index}>
                                                        <img src={image} className="sku-img" />
                                                        <div className="mt-2">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                className="bi bi-x-circle sku-icon cursor-pointer"
                                                                viewBox="0 0 16 16"
                                                                onClick={() => { handleRemoveClick(index) }}
                                                            >
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                            </svg>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </FormGroup>
                                    </div>
                                )}

                                {isInvalidForm && imageArray.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.IMAGE_VALID}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Ingredients*</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddIngredients}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.ingredients?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-1">
                                                    <Input
                                                        placeholder="Ingredients Name"
                                                        className={`${isInvalidForm && !option.name ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.name}
                                                        name={`ingredients[${index}].name`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Ingredients Description"
                                                        className={`${isInvalidForm && !option.description ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.description}
                                                        name={`ingredients[${index}].description`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveIngredients(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                                {isInvalidForm && values.ingredients.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.OPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Cooking Instructions*</label>
                                <FormGroup id="cookingInstructions">
                                    <Textarea
                                        rows={5}
                                        placeholder="Recipe Cooking Instructions"
                                        name="cookingInstructions"
                                        value={values.cookingInstructions}
                                        onChange={handleChange}
                                        className={`${isInvalidForm && !values.cookingInstructions
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.cookingInstructions && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Recipe Nutrition Info</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddNutritionOption}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.nutritionInfo?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-1">
                                                    <Input
                                                        placeholder="Recipe Nutrition Type"
                                                        className={`${isInvalidForm && !option.type ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.type}
                                                        name={`nutritionInfo[${index}].type`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Recipe Nutrition Grams"
                                                        className={`${isInvalidForm && !option.gms ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.gms}
                                                        name={`nutritionInfo[${index}].gms`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveNutritionOption(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6" key="metric">
                                <label className="form-labels">Select Metrics*</label>

                                <FormGroup id="metric">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const metric = selectedOptions.map((metrics: any) => ({
                                                id: metrics.value,
                                                label: metrics.label,
                                            }));

                                            const metrics = selectedOptions.map((metric: any) => metric.value);
                                            setSelectedMetricIdArr(metrics);

                                            setSelectedMetricName(metric);
                                        }}
                                        options={
                                            metricData &&
                                            metricData
                                                .map((metric: any) => ({
                                                    value: metric._id,
                                                    label: metric.metricName,
                                                }))
                                        }
                                        placeholder="Select Metrics"
                                        value={selectedMetricId}
                                        isSearchable={true}
                                        isMulti
                                        className={`${isInvalidForm && selectedMetricId.length === 0
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedMetricId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.METRIC_ID}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe ChefTips</label>
                                <FormGroup id="chefTips">
                                    <Input
                                        placeholder="Recipe ChefTips"
                                        name="chefTips"
                                        value={values.chefTips}
                                        className={`${isInvalidForm && !values.chefTips
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe pairing Suggestions</label>
                                <FormGroup id="pairingSuggestions">
                                    <Input
                                        placeholder="Recipe Pairing Suggestions"
                                        name="pairingSuggestions"
                                        value={values.pairingSuggestions}
                                        className={`${isInvalidForm && !values.pairingSuggestions
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Make It Your Own</label>
                                <FormGroup id="makeItYourOwn">
                                    <Input
                                        placeholder="Recipe Make It Your Own"
                                        name="makeItYourOwn"
                                        value={values.makeItYourOwn}
                                        onChange={handleChange}
                                        className={`${isInvalidForm && !values.makeItYourOwn
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Recipe Why*</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddOption}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.whyRecipe?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-1">
                                                    <Input
                                                        placeholder="Recipe Why Name"
                                                        className={`${isInvalidForm && !option.name ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.name}
                                                        name={`whyRecipe[${index}].name`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Recipe Why Description"
                                                        className={`${isInvalidForm && !option.description ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.description}
                                                        name={`whyRecipe[${index}].description`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveOption(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                                {isInvalidForm && values.whyRecipe.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.OPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Cooking Time</label>
                                <FormGroup id="cookingTime">
                                    <Input
                                        placeholder="Recipe Cooking Time"
                                        name="cookingTime"
                                        value={values.cookingTime}
                                        onChange={handleChange}
                                        className={`${isInvalidForm && !values.cookingTime
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.cookingTime && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6 d-flex flex-column">
                                <label className="form-labels"> Select Dietary Preference*</label>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-checkbox-label">Select Dietary Preference*</InputLabel>
                                    <MuiSelect
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        error={isInvalidForm && recipeName.length === 0}
                                        value={recipeName}
                                        onChange={handleChangeRecipe}
                                        input={<OutlinedInput label="Select Dietary Preference" />}
                                        className={isInvalidForm && recipeName.length === 0 ? "border-danger" : ""}
                                        renderValue={renderSelectedValues}
                                    >
                                        {RecipeOption.map((option) => (
                                            <MenuItem key={option._id} value={option.value}>
                                                <Checkbox checked={recipeName.indexOf(option.value) > -1} />
                                                <ListItemText primary={option.label} />
                                            </MenuItem>
                                        ))}
                                    </MuiSelect>
                                </FormControl>
                                {isInvalidForm && recipeName.length === 0 && (
                                    <FormHelperText error>{CONSTANT.REQUIRED}</FormHelperText>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Cooking Complexity*</label>
                                <FormGroup id="cookingComplexity">
                                    <select
                                        className={`${isInvalidForm && values.cookingComplexity.length === 0
                                            ? "border border-danger  form-select form-selet-lg input-form"
                                            : "form-select form-selet-lg input-form"
                                            }`}
                                        name="cookingComplexity"
                                        value={values.cookingComplexity}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Cooking Complexity</option>
                                        <option value={CONSTANT.COOKING_COMPLEXITY.EASY}>
                                            {CONSTANT.COOKING_COMPLEXITY.EASY}
                                        </option>
                                        <option value={CONSTANT.COOKING_COMPLEXITY.MEDIUM}>
                                            {CONSTANT.COOKING_COMPLEXITY.MEDIUM}
                                        </option>
                                        <option value={CONSTANT.COOKING_COMPLEXITY.HARD}>
                                            {CONSTANT.COOKING_COMPLEXITY.HARD}
                                        </option>
                                    </select>

                                </FormGroup>

                                {isInvalidForm && values.cookingComplexity.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Cuisine Type</label>
                                <FormGroup id="cuisineType">
                                    <Input
                                        placeholder="Cuisine Type"
                                        name="cuisineType"
                                        value={values.cuisineType}
                                        onChange={handleChange}
                                        className={`${isInvalidForm && !values.cuisineType
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Allergens Excluded</label>
                                <FormGroup id="allergensExcluded">
                                    <Input
                                        placeholder="Allergens Excluded"
                                        name="allergensExcluded"
                                        value={values.allergensExcluded}
                                        onChange={handleChange}
                                        className={`${isInvalidForm && !values.allergensExcluded
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.allergensExcluded && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Taste Meter</label>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-checkbox-label">Select tastes and ranks</InputLabel>
                                    <MuiSelect
                                        labelId="taste-select-label"
                                        multiple
                                        value={Object.keys(selectedTastes).filter(taste => selectedTastes[taste] !== '')}
                                        onClose={handleDropdownClose}
                                        renderValue={() => displayedSelection || 'Select tastes and ranks'}
                                        input={<OutlinedInput label="Select tastes and ranks" />}
                                    >
                                        {TasteOption.map((option: any) => (
                                            <MenuItem key={option.value} value={option.label}>
                                                <Box display="flex" alignItems="center" justifyContent="space-around" width="100%">
                                                    <Checkbox
                                                        checked={selectedTastes[option.label] !== ''}
                                                        onChange={(e) => handleChangeCheckbox(e, option.label)}
                                                    />
                                                    <Box flex={1}>{option.label}</Box>
                                                    {selectedTastes[option.label] !== '' && (
                                                        <TextField
                                                            type="number"
                                                            label="Select taste meter out of 10"
                                                            value={selectedTastes[option.label]}
                                                            onChange={(e: any) => handleChangeRank(e, option.label)}
                                                            inputProps={{ min: 0, max: 10 }}
                                                            style={{ marginLeft: '10px', flex: 1 }}
                                                        />
                                                    )}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </MuiSelect>
                                </FormControl>
                            </div>

                            <div className="col-sm-12 col-md-6 d-flex flex-column">
                                <label className="form-labels"> Select Cooking Device Type*</label>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-checkbox-label">Select Cooking Device Type*</InputLabel>
                                    <MuiSelect
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        error={isInvalidForm && cookingDeviceName.length === 0}
                                        value={cookingDeviceName}
                                        onChange={handleChangeCookingDevice}
                                        input={<OutlinedInput label="Select Cooking Device Type" />}
                                        renderValue={renderSelectedCookingDeviceValues}
                                        className={isInvalidForm && cookingDeviceName.length === 0 ? "border-danger" : ""}
                                    >
                                        {CookingDeviceOption.map((option) => (
                                            <MenuItem key={option._id} value={option.value}>
                                                <Checkbox checked={cookingDeviceName.indexOf(option.value) > -1} />
                                                <ListItemText primary={option.label} />
                                            </MenuItem>
                                        ))}
                                    </MuiSelect>
                                    {isInvalidForm && cookingDeviceName.length === 0 && (
                                        <FormHelperText error>{CONSTANT.REQUIRED}</FormHelperText>
                                    )}
                                </FormControl>
                            </div>

                            <div className="col-sm-12 col-md-6" key="mealType">
                                <label className="form-labels">Select Meal Type*</label>

                                <FormGroup id="mealType">
                                    {MealOption.map((option) => (
                                        <FormControlLabel
                                            key={option._id}
                                            control={
                                                <Checkbox
                                                    value={option.value}
                                                    onChange={handleCheckboxChange}
                                                    checked={checkedValues.includes(option.value)}
                                                />
                                            }
                                            label={option.sourceName}
                                        />
                                    ))}
                                </FormGroup>

                            </div>

                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={() => setIsInvalidForm(true)}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {recipeId ? CONSTANT.UPDATE : CONSTANT.ADD} Recipe
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditRecipe;
